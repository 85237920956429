export const format = (input, separator = '/', regular = true) => {
  const date = new Date(input);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (regular) {
    return `${fillZero(day)}${separator}${fillZero(month)}${separator}${year}`;
  } else {
    return `${year}${separator}${fillZero(month)}${separator}${fillZero(day)}`;
  }
};

const fillZero = (input) => {
  const value = input.toString();
  return value.length === 2 ? value : `0${value}`;
};

export const actualYear = new Date().getFullYear();